import { Box, LinearProgress } from '@mui/material';

const Loading = () => {
    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
            }}
        >
            <LinearProgress />
        </Box>
    );
};

export default Loading;
