import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
} from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import {
    ISnackbarAlert,
    ISnackbarContext,
    ISnackbarData,
} from '../models/contexts/snackbar';

const SnackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);

export const SnackbarContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [data, setData] = useState<Partial<ISnackbarData>>({});

    const handleClose = () => {
        setData((prevState) => ({ ...prevState, open: false }));
    };

    const showAlert = (alert: ISnackbarAlert) => {
        setData({ ...alert, open: true });
    };

    return (
        <SnackbarContext.Provider value={{ showAlert }}>
            {children}
            <Snackbar
                open={data.open}
                autoHideDuration={data.duration ?? 8000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity={data.severity}>
                    {data?.title ? <AlertTitle>{data.title}</AlertTitle> : null}
                    {data.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = () => useContext(SnackbarContext);
