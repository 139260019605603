import { Container } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface ContentProps {}

const Content: React.FC<ContentProps & PropsWithChildren> = ({ children }) => {
    return <Container sx={{ flexGrow: 1, overflow: 'auto'  }}>{children}</Container>;
};

export default Content;
