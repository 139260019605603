import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './configurations/material-ui-theme';
import AppRoutes from './routes';
import { SnackbarContextProvider } from './contexts/SnackbarContext';
import Layout from './components/Layout';
import { LayoutContextProvider } from './contexts/LayoutContext';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LayoutContextProvider>
                    <Layout>
                        <SnackbarContextProvider>
                            <AppRoutes />
                        </SnackbarContextProvider>
                    </Layout>
                </LayoutContextProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
