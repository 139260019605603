import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { routes } from '../configurations/routes';
import Loading from '../components/Loading';

const AppRoutes = () => {
    return (
        <Routes>
            {routes.map(({ id, path, element: Element }) => (
                <Route
                    key={id}
                    path={path}
                    element={
                        <Suspense fallback={<Loading />}>
                            <Element />
                        </Suspense>
                    }
                />
            ))}
        </Routes>
    );
};
export default AppRoutes;
