import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import landscapeImg from '../../assets/imgs/landscape.png';
import portraitImg from '../../assets/imgs/portrait.png';

import Content from './Content';

interface LayoutProps {}

const Layout: FC<LayoutProps & PropsWithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100vh',
                backgroundColor: '#0C499C',
                backgroundImage: {
                    xs: `url(${portraitImg})`,
                    md: `url(${landscapeImg})`,
                },
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <Content>{children}</Content>
        </Box>
    );
};

export default Layout;
