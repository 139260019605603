import React, {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

import { ILayoutContext } from '../models/contexts/layout';

const LayoutContext = createContext({} as ILayoutContext);

interface Props {}

export const LayoutContextProvider: FC<Props & PropsWithChildren> = ({
    children,
}) => {
    const [titlePage, setTitlePage] = useState('Ranking');

    useEffect(() => {
        document.title = titlePage;
    }, [titlePage]);

    const changeTitlePage = (title: string) => {
        setTitlePage(title);
    };

    return (
        <LayoutContext.Provider
            value={{
                titlePage,
                changeTitlePage,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayout = () => useContext(LayoutContext);
