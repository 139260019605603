import { IRoute } from '../models/configurations/route';
import { lazy } from 'react';

export const routes: IRoute[] = [
    {
        id: 'register',
        path: '/',
        element: lazy(() => import('../pages/lead/Register')),
    },
    {
        id: 'ranking',
        path: '/ranking',
        element: lazy(() => import('../pages/lead/Ranking')),
    },
    {
        id: 'ranking-report',
        path: '/admin/ranking-report',
        element: lazy(() => import('../pages/admin/RankingReport')),
    },
    {
        id: 'not-found',
        path: '*',
        element: lazy(() => import('../pages/common/NotFound')),
    },
];
